import { breakpoints, ThemeOverrides } from "@shapeable/theme";
import { css } from "styled-components";

export const YELLOW = '#FFCA04';
export const YELLOW_DARK = '#FCB10F';
export const YELLOW_ORANGE = '#F79616';

export const themeOverrides: ThemeOverrides = {
  light: {
    color: {

      darkBackground: '#202124',
      lightBackground: '#F8F8F8',
  
      midBrand: YELLOW_DARK,
      primary: YELLOW_DARK,
  
  
      linkHover: YELLOW,
      link: YELLOW_DARK,
      // linkInvertedHover: YELLOW,
      linkActive: YELLOW,
  
      // menuItemLinkHover: YELLOW_DARK, 
      // menuItemLinkActive: YELLOW_DARK, 
      // invertedMenuItemLinkHover: YELLOW_DARK, 
  
    },
  },
  font: {
    family: {
      serif: "Cormorant, georgia, serif",
      sans: 'Inter, sans-serif',
      heading: 'Inter, sans-serif',
      copy: 'Inter, sans-serif',
    }
  },
};


/* 
--------------------

  Layout Styles
  
  NB: This is the appropriate place to override styling for this specific site, based on BEM-style class name targets 
  
--------------------
*/

export const LayoutStyles = breakpoints({
  base: css`
    .shp--site-menu-bar {
      
    }

    .shp--slice-intro {
      font-size: 1em;
      text-align: center;
      line-height: 1.5em;
      font-weight: 200;
    }

    .shp--block-image-content {
      gap: 16px;
    }

  `,
  tablet: css`
    .shp--slice-intro {
      font-size: 1.3em;
    }
    .shp--slice-organisations__organisations {
      justify-content: center;
    }

   .shp--tabs__body {
      font-size: 1.2em;
    }
  `,
  desktop: css`
    
  `,
});